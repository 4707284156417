import { DeploymentStageName, LogLevel, TargetUserGroup } from '@trustedshops/tswp-core-common';

export const environment = {
  production: true,
  name: DeploymentStageName.QA,
  targetGroup: TargetUserGroup.BusinessToBusiness,
  pageTitle: 'eTrusted Control Centre',
  tracking: {
    googleAnalytics: 'UA-53707107-45',
    googleAnalytics4: 'G-E3DQ4T285P',
    hotJar: 3248368,
    hubspot: {
      id: '603347',
      scriptUrl: '//js.hs-scripts.com/603347.js',
    }
  },
  apis: {
    plugins: 'https://app-api.etrusted.site/v0',
    permissions: 'https://b2b-permissions-qa.trustedshops.com/v1',
    users: 'https://sso-apis-qa.etrusted.com/um-v1',
    accounts: 'https://api.etrusted.site',
    featureBooking: 'https://booking-api.etrusted.site/v0'
  },
  startPage: {
    url: '/home'
  },
  diagnostics: {
    logging: {
      logLevels: [
        LogLevel.Warning,
        LogLevel.Error,
        LogLevel.Fatal
      ],
      filters: [],
      sentry: {
        environment: DeploymentStageName.QA,
        logLevels: [
          LogLevel.Error,
          LogLevel.Fatal
        ],
        dsn: 'https://ad6c7ff7bab74ba1a5923520c97a6e42@o128203.ingest.sentry.io/5614223',
        browserTracingOrigins: [
          'app-qa.trustedshops.com',
          'app-api-qa.trustedshops.com',
          'app.etrusted.site',
          'app-api.etrusted.site',
          'api.etrusted.site',
          'admin.etrusted.site'
        ]
      },
      instana: {
        logLevels: [
          LogLevel.Error,
          LogLevel.Fatal,
        ],
        browserTracingOrigins: [],
        reportingUrl: 'https://eum-blue-saas.instana.io',
        key: 'UK4fSW93Q2G6EAvODUAL_g',
        scriptUrl: 'https://eum.instana.io/eum.min.js',
      },
    }
  },
  authorization: {
    keycloakUrl: 'https://login-qa.etrusted.com/auth/',
    clientId: 'tswp-carrier-b2b',
    realm: 'business-QA',
    sessionVerificationType: 'check-sso',
    silentCheckSsoRedirectUri: '/assets/authentication/token-proxy.html'
  },
  layout: {
    resources: {
      heliosUri: 'https://app.etrusted.site/helios.css',
      heliosIconUri: 'https://cdn-qa.trustedshops.com/helios/helios-icons/1.7.20/raw/',
    }
  },
  storage: {
    plugins: {
      rootPath: 'https://app.etrusted.site/plugins'
    }
  },
  feedback: {
    api: 'https://api.etrusted.site/nps-questionnaire-link',
    templateBase: 'tpl-qst-46d24265-bf2c-4b2d-b35b-2b19f6c153bc_',
  },
  freeAccount: {
    hubspot: {
      upgradePage: {
        baseUrl: 'https://api.hsforms.com',
        authToken: 'pat-na1-524944ba-9083-486b-ac3e-b8fc897b975a',
        portalId: '603347',
        fullContactFormId: '36150cd0-8ab0-4d60-b0ba-373048ce9610',
        shortContactFormId: 'a406a202-7cdd-4634-b304-b4f34a205783',
        ctaContactFormId: '9e1884cb-849e-42e3-9dd7-1feff3c3b778',
      },
    },
  },
  userFlow: {
    apiKey: 'ct_r36fodd4bjh6xg7oyqdougtydi'
  }
};
